@use 'sass:map';
@use 'sass:color';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $background: map.get($config, background);

  app-widget-titlebar {

    & {
      background: if($is-dark-theme, rgba(0,0,0,0.6), #eee);
    }


    button.close,
    button.maximize,
    button.restore {
      color: if($is-dark-theme, #ccc, rgba(0,0,0,0.6));
    }

    button.close:hover,
    button.maximize:hover,
    button.restore:hover {
      background: if($is-dark-theme, #222, #ccc);
      color: if($is-dark-theme, #ccc, black);
    }


  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
