@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $is-dark-theme: map.get($config, is-dark);
  $primary: map.get($config, primary);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  app-chat-screen {
    .content {
      scrollbar-color: mat.get-color-from-palette($foreground, hint-text, 0.3) transparent;
    }

    .loading-spinner {
      --mdc-circular-progress-active-indicator-color: #{mat.get-color-from-palette($foreground, hint-text)};
    }

    .self-message {
      background-color: mat.get-color-from-palette($background, hover);
    }

    .other-message.error {
      background: mat.get-color-from-palette(mat.$red-palette, if($is-dark-theme, 800, 500), 0.2);
      border-color: mat.get-color-from-palette(mat.$red-palette, if($is-dark-theme, 800, 500), 0.8);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  app-chat-screen {
    .other-message {
      :first-child {
        margin-top: 0;
      }

      :last-child {
        margin-bottom: 0;
      }

      :is(h2, h3, h4, h5) {
        font-weight: 600;
      }

      p {
        margin-top: 1.25em;
        margin-bottom: 0.5rem;
      }

      h1 {
        font-size: 2.25rem;
        font-weight: 700;
        letter-spacing: -0.04rem;
        line-height: 1.11;
        margin-top: 0;
        margin-bottom: 2rem;
      }

      h2 {
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1.33;
      }

      :is(h3, h4) {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }

      h3 {
        font-size: 1.25rem;
        line-height: 1.6;
      }

      h4 {
        font-size: 1rem;
        line-height: 1.5;
      }

      :is(h5, h6) {
        font-size: 1rem;
        margin: 0;
      }

      h6 {
        font-weight: 500;
      }

      :where(h2, h3, h4) + * {
        margin-top: 0 !important;
      }

      ul {
        list-style-type: disc;
      }

      ol {
        list-style-type: decimal;
      }

      li {
        padding-left: 0.375rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      :where(ul, ol) {
        padding-left: 1.625rem;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
      }

      code {
        font-size: 0.9em;
      }

      & > pre[class*='language-'] {
        border-radius: 0.375rem;
      }
    }
  }
}
